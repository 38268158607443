import { NgModule } from '@angular/core';
import {
  NoPreloading,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
import { LayoutAdminComponent } from './layouts/layout-admin/layout-admin.component';
import { ShippingSpacesComponent } from './pages/shipping-spaces/shipping-spaces.component';
import { LayoutAuthComponent } from './layouts/layout-auth/layout-auth.component';
import { AuthGuard } from './core/guards/auth.guard';
import { loggedAdminResolver } from './core/resolvers/administrator/logged-admin.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },

  {
    path: 'auth',
    component: LayoutAuthComponent,
    loadChildren: () => import('./pages/authentication/routes'),
  },
  {
    path: 'panel',
    canActivate: [AuthGuard],
    resolve: { data: loggedAdminResolver },
    component: LayoutAdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        data: {
          breadcrumb: 'Tableau de bord',
        },
        loadComponent: () =>
          import(
            './pages/dashboard/dashboard-home/dashboard-home.component'
          ).then((x) => x.DashboardHomeComponent),
      },
      {
        path: 'states',
        data: {
          breadcrumb: 'Etats',
        },
        loadChildren: () =>
          import('./pages/state/state.module').then((st) => st.StateModule),
      },
      {
        path: 'payment-request',
        data: {
          breadcrumb: 'Demande de paiements',
        },
        loadChildren: () => import('./pages/payment-request/routes'),
      },

      {
        path: 'notifications',
        data: {
          breadcrumb: 'Notifications',
        },
        loadChildren: () =>
          import('./notification/notification.module').then(
            (not) => not.NotificationModule
          ),
      },
      {
        // /panel/admins
        path: 'admins',
        data: {
          breadcrumb: 'Administrateurs',
        },
        loadChildren: () => import('./pages/administrators/routes'),
      },
      {
        // /panel/account

        path: 'account',
        data: {
          breadcrumb: 'Gestion de compte',
        },
        loadChildren: () => import('./pages/manage-account/routes'),
      },

      {
        path: 'referential',

        data: {
          breadcrumb: 'Referentiel',
        },
        loadChildren: () =>
          import('./pages/refrential/refrential.module').then(
            (re) => re.RefrentialModule
          ),
      },

      {
        path: 'faq',

        data: {
          breadcrumb: 'FAQ',
        },
        loadChildren: () =>
          import('./pages/faq/faq.module').then((faq) => faq.FaqModule),
      },

      {
        path: 'shipments',
        data: {
          breadcrumb: 'Colis',
        },
        loadChildren: () => import('./pages/shipment/routes'),
      },

      {
        // /panel/equipments

        path: 'equipments',
        data: {
          breadcrumb: 'Gestion des engins',
        },
        loadChildren: () => import('./pages/manage-equipments/routes'),
      },
      {
        // /panel/settings

        path: 'settings',
        data: {
          breadcrumb: 'Paramètres',
        },
        loadChildren: () =>
          import('./pages/settings/settings.module').then(
            (x) => x.SettingsModule
          ),
      },
      {
        // /panel/shipping
        path: 'shipping',
        data: {
          breadcrumb: 'Commandes',
        },
        component: ShippingSpacesComponent,
        loadChildren: () => import('./pages/shipping-spaces/routes'),
      },
      {
        path: 'teams',
        data: {
          breadcrumb: 'Personnels',
        },
        loadChildren: () => import('./pages/team/routes'),
      },
      {
        path: 'pricing',
        data: {
          breadcrumb: 'Tarifications',
        },
        loadChildren: () => import('./pages/pricing/routes'),
      },
      {
        // /panel/shipping
        path: 'security',
        data: {
          breadcrumb: 'Sécurité',
        },
        loadChildren: () =>
          import('./pages/security/security.module').then(
            (sc) => sc.SecurityModule
          ),
      },
      {
        path: 'otp-codes',
        loadComponent: () =>
          import('./pages/otp-codes/otp-codes.component').then(
            (x) => x.OtpCodesComponent
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // useHash: true,
      preloadingStrategy: PreloadAllModules,
      scrollOffset: () => [0, 0],
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
