import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppComponent } from './app/app.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { NzImageService } from 'ng-zorro-antd/image';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  LOCALE_ID,
  DEFAULT_CURRENCY_CODE,
  importProvidersFrom,
} from '@angular/core';
import { ErrorInterceptor } from './app/core/helpers/error.interceptor';
import { JwtInterceptor } from './app/core/helpers/jwt.interceptor';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
} from '@angular/common/http';
import { ngZorroConfig } from './app/core/ngZorroConfig';
import { NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NZ_I18N, fr_FR, provideNzI18n } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr);
bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, AppRoutingModule, FormsModule),
    { provide: NZ_I18N, useValue: fr_FR },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR',
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'CFA' },
    // {provide: LocationStrategy, useClass: HashLocationStrategy},
    NzMessageService,
    provideNzI18n(fr_FR),
    NzNotificationService,
    NzModalService,
    NzDrawerService,
    NzImageService,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
