<div class="w-full flex min-h-screen">
  <!-- Menu latéral -->
  <aside
    [class.w-[300px]]="isMenuOpen"
    [class.w-[0px]]="!isMenuOpen"
    class="bg-gray-50 flex flex-col h-screen sticky top-0 transition-all duration-300 ease-in-out"
    [class.hidden]="!isMenuOpen"
    [class.block]="isMenuOpen"
  >
    <header class="p-6 border-b border-gray-200">
      <div class="flex flex-col items-center gap-3">
        <img
          src="assets/weni-logo.png"
          class="!h-12 !w-12 object-contain"
          alt="Logo Weni"
        />
        <h1 class="text-xl font-semibold text-gray-800">Administrateur</h1>
      </div>
    </header>

    <nav class="flex-1 overflow-y-auto p-4">
      <div class="flex flex-col gap-2">
        <div
          *ngFor="let menu of menus"
          (click)="handleMenuClick(menu)"
          class="flex flex-col"
        >
          <div
            class="flex items-center gap-3 p-3 !rounded-[10px] transition-all hover:bg-green-50 cursor-pointer"
            [class.bg-green-100]="menu.nzActive"
          >
            <img
              [src]="'assets/dashboard/' + menu.nzSourceImage"
              class="!w-5 !h-5 object-contain"
              alt="Icon {{ menu.nzLabel }}"
            />
            <div class="flex items-center justify-between flex-1">
              <span class="text-gray-700">{{ menu.nzLabel }}</span>
              <svg
                *ngIf="menu.nzSubmenu"
                class="w-4 h-4 text-gray-500 transition-transform duration-200"
                [class.rotate-180]="menu.nzOpen"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M7 10l5 5 5-5z" fill="currentColor" />
              </svg>
            </div>
          </div>

          <!-- Submenu -->
          <div
            *ngIf="menu.nzSubmenu && menu.nzOpen"
            class="ml-8 mt-1 flex flex-col gap-1"
          >
            <div
              *ngFor="let submenu of menu.nzSubmenuItem"
              (click)="handleSubmenuClick($event, menu, submenu)"
              class="p-2 rounded-md hover:bg-green-50 cursor-pointer transition-all"
              [class.bg-green-100]="submenu.active"
            >
              <span class="text-gray-600 text-sm">{{ submenu.label }}</span>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <footer class="p-4 border-t border-gray-200">
      <p
        class="text-sm text-gray-600 leading-relaxed text-center hover:cursor-pointer hover:p-2 hover:rounded-lg transition-all ease-in-out duration-200 hover:bg-[#34b76e] hover:text-white hover:rounded-lg"
        (click)="goParameter()"
      >
        Paramètre
      </p>
    </footer>
  </aside>

  <!-- Contenu principal -->
  <main class="bg-white flex-1 h-screen overflow-x-hidden overflow-y-auto">
    <!-- Bouton pour afficher/masquer le menu sur les petits écrans -->
    <button
      class="md:hidden p-2 bg-gray-200 rounded-lg mb-4"
      (click)="toggleMenu()"
    >
      Menu
    </button>

    <weni-top-header></weni-top-header>
    <div class="p-4">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
