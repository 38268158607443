import { Component, signal } from '@angular/core';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { IAdmin } from 'src/app/core/interfaces/iadmin';
import { AuthService } from 'src/app/core/services/auth.service';
import { UtilsService } from 'src/app/core/services/utils.service';
@Component({
  selector: 'weni-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.less'],
  imports: [NzBreadCrumbModule, NzIconModule, NzDropDownModule],
  standalone: true,
})
export class TopHeaderComponent {
  user!: IAdmin;

  constructor(private service: AuthService, private utils: UtilsService) {
    this.user = this.service.adminProfil()!;
  }

  logout() {
    this.utils.LogOut();
  }
}
