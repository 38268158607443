export enum API_RESPONSE_STATUS {
  SUCCESS = 200,
  SUCCESS_2000 = 2000,
  NOT_FOUND = 4000,
  INVALID_DATA = 422,
  PASSWORD_NOT_MATCH = 505,
  ALREADY_EXIST = 404,
}

export const VEHICLES_TYPES: Array<{
  title: string;
  value: string;
}> = [
  {
    title: 'Car',
    value: 'CAR',
  },
  {
    title: 'Camion',
    value: 'TRUCK',
  },
  {
    title: 'Motocycle',
    value: 'MOTORCYCLE',
  },
  {
    title: 'Bicyclette',
    value: 'BICYCLE',
  },
  {
    title: 'Autres',
    value: 'OTHER',
  },
];

export const DELIVERY_STATUS = [
  {
    libelle: 'Non assigné',
    value: 'NOT_ASSIGNED',
  },
  {
    libelle: 'Assigner a un livreur',
    value: 'ASSIGNED',
  },
  {
    libelle: 'Livraison en cours',
    value: 'IN_PROGRESS',
  },
  {
    libelle: 'Livrer',
    value: 'DELIVERED',
  },
  {
    libelle: 'Annuler',
    value: 'CANCELED',
  },
  {
    libelle: 'Retourner',
    value: 'RETURNED',
  },
  {
    libelle: 'Refuser',
    value: 'REFUSED',
  },
  {
    libelle: 'Perdu',
    value: 'LOST',
  },
  {
    libelle: 'Inconnu',
    value: 'UNKNOWN',
  },
];

export const SHIPMENT_ASSIGN_ACTIONS = [
  {
    value: 'ASSIGN',
    title: 'Assignation manuelle',
  },
  {
    value: 'AUTO_ASSIGN',
    title: 'Assignation automatique',
  },
  {
    value: 'TRANSFER',
    title: 'Transfert',
  },
  {
    value: 'REASSIGN',
    title: 'Reaffectation',
  },
  {
    value: 'REFUSE',
    title: 'Refus',
  },
  {
    value: 'CANCEL',
    title: 'Annulation',
  },
  {
    value: 'UNASSIGN',
    title: 'Désassignation',
  },
];

export const SHIPMENT_STATE = [
  // { libelle: 'Non attribué', value: 'NOT_ASSIGNED' },
  // { libelle: 'Attribué', value: 'ASSIGNED' },
  { libelle: 'En cours de livraison', value: 'IN_PROGRESS' },
  { libelle: 'Livré', value: 'DELIVERED' },
  { libelle: 'Annulé', value: 'CANCELED' },
  { libelle: 'Retourné', value: 'RETURNED' },
  // { libelle: 'Retour payé', value: 'RETURNED_PAID' },
  { libelle: 'Relancé', value: 'RELAUNCHED' },
  { libelle: 'Perdu', value: 'LOST' },
  // { libelle: 'Recherche livreur', value: 'SEARCHING_DELIVERYMAN' },
  // { libelle: 'Inconnu', value: 'UNKNOWN' },
];

export const SHIPMENT_STATE_TO_RENDER = [
  { libelle: 'En cours de livraison', value: 'IN_PROGRESS' },
  { libelle: 'Livré', value: 'DELIVERED' },
  { libelle: 'Annulé', value: 'CANCELED' },
  { libelle: 'Retourné', value: 'RETURNED' },
  { libelle: 'Retour payé', value: 'RETURNED_PAID' },
  { libelle: 'Relancé', value: 'RELAUNCHED' },
  { libelle: 'Perdu', value: 'LOST' },
  { libelle: 'Recherche livreur', value: 'SEARCHING_DELIVERYMAN' },
  { libelle: 'En attente', value: 'UNKNOWN' },
];

export const PAYMENT_VALIDATION_ENUM = [
  {
    id: 'CREATED',
    label: 'Demande initié',
  },
  {
    id: 'PROCESSING',
    label: 'Traitement en cours',
  },
  {
    id: 'REJECTED',
    label: 'Rejetée',
  },
  {
    id: 'CANCELED',
    label: 'Annulée',
  },
  {
    id: 'COMPLETED',
    label: 'Valider',
  },
];
