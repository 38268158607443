import { Component, OnInit } from '@angular/core';
import { IMenuItem } from '../../core/interfaces/IMenuItem';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { mappingRoutes } from '../../core/mappingRoutes';
import { UtilsService } from 'src/app/core/services/utils.service';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { PageLoadingWidgetComponent } from '../page-loading-widget/page-loading-widget.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { ItemSubmenuComponent } from '../../shared/items/item-submenu/item-submenu.component';
import { ItemMenuComponent } from '../../shared/items/item-menu/item-menu.component';
import { NgFor, NgClass, NgIf, CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TopHeaderComponent } from 'src/app/shared/top-header/top-header.component';

@Component({
  selector: 'weni-layout-admin',
  templateUrl: './layout-admin.component.html',
  styleUrls: ['./layout-admin.component.less'],
  standalone: true,
  imports: [
    CommonModule,
    NzLayoutModule,
    NzSpaceModule,
    NzAvatarModule,
    NzDropDownModule,
    NzMenuModule,
    NzButtonModule,
    TopHeaderComponent,
    NgFor,
    ItemMenuComponent,
    ItemSubmenuComponent,
    NgClass,
    NzDividerModule,
    NzWaveModule,
    NzIconModule,
    NgIf,
    NzGridModule,
    NzFormModule,
    NzInputModule,
    PageLoadingWidgetComponent,
    NzBreadCrumbModule,
    RouterOutlet,
  ],
})
export class LayoutAdminComponent implements OnInit {
  isMenuOpen: boolean = true;

  menus: IMenuItem[] = [
    {
      nzSourceImage: '01.svg',
      nzLabel: 'Tableau de bord',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: false,
      pathRedirect: mappingRoutes.dashboard,
      nzSubmenuItem: [],
    },
    {
      nzSourceImage: 'Mail.svg',
      nzLabel: 'Code SMS OTP',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: false,
      pathRedirect: mappingRoutes.otps,
      nzSubmenuItem: [],
    },
    {
      nzSourceImage: '02.svg',
      nzLabel: 'Paiements',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: false,
      pathRedirect: mappingRoutes.paymentRequest.list,
      nzSubmenuItem: [],
    },
    {
      nzSourceImage: '03.svg',
      nzLabel: 'Administrateurs',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: false,
      pathRedirect: mappingRoutes.administrators.admins,
      nzSubmenuItem: [],
    },
    {
      nzSourceImage: '04.svg',
      nzLabel: 'Commandes',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: true,
      nzSubmenuItem: [
        {
          label: 'Clients',
          active: true,
          pathRedirect: mappingRoutes.customerOrders.list,
        },
        {
          label: 'Fournisseurs',
          active: false,
          pathRedirect: mappingRoutes.supplierDelivery.list,
        },
        {
          label: 'Colis',
          active: false,
          pathRedirect: mappingRoutes.shipment.list,
        },
        {
          label: 'Localisation livreur',
          active: false,
          pathRedirect: mappingRoutes.shipment.driverGlobalLocation,
        },

        {
          label: 'Rapport',
          active: false,
          pathRedirect: mappingRoutes.report.list,
        },

        {
          label: 'Rapport journalier',
          active: false,
          pathRedirect: mappingRoutes.states.index,
        },
      ],
    },
    {
      nzSourceImage: '05.svg',
      nzLabel: 'Gestion de compte',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: true,
      nzSubmenuItem: [
        // {
        //   label: 'Boutiques',
        //   active: true,
        //   pathRedirect: mappingRoutes.manageAccount.shop,
        // },
        {
          label: 'Fournisseurs',
          active: false,
          pathRedirect: mappingRoutes.manageAccount.fournisseur.list,
        },
        {
          label: 'Commerciaux',
          active: false,
          pathRedirect: mappingRoutes.manageAccount.sales,
        },
        {
          label: 'Logisticiens',
          active: false,
          pathRedirect: mappingRoutes.manageAccount.logisticians,
        },
        {
          label: 'Livreurs',
          active: false,
          pathRedirect: mappingRoutes.manageAccount.drivers,
        },
        {
          label: 'Utilisateurs mobiles',
          active: false,
          pathRedirect: mappingRoutes.manageAccount.customers.list,
        },
      ],
    },
    {
      nzSourceImage: '06.svg',
      nzLabel: 'Gestion des engins',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: true,
      nzSubmenuItem: [
        {
          label: 'Engins',
          active: true,
          pathRedirect: mappingRoutes.equipments.engine,
        },
        // {
        //   label: 'Historique',
        //   active: false,
        //   pathRedirect: mappingRoutes.equipments.histories,
        // },
        {
          label: 'Sinistres',
          active: false,
          pathRedirect: mappingRoutes.equipments.claims,
        },
      ],
    },
    {
      nzSourceImage: '07.svg',
      nzLabel: 'Sécurité',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: true,
      nzSubmenuItem: [
        {
          label: 'Profils',
          active: true,
          pathRedirect: mappingRoutes.profiles.list,
        },
        {
          label: 'Permissions',
          active: false,
          pathRedirect: mappingRoutes.permissions.list,
        },
      ],
    },

    {
      nzSourceImage: '08.svg',
      nzLabel: 'Référentiel',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: true,
      nzSubmenuItem: [
        {
          label: 'Zones',
          active: true,
          pathRedirect: mappingRoutes.referential.area.list,
        },

        {
          label: 'Motif de relance',
          active: false,
          pathRedirect: mappingRoutes.referential.relaunch.list,
        },
        {
          label: 'Motif de retour',
          active: false,
          pathRedirect: mappingRoutes.referential.return.list,
        },
        {
          label: 'Marques',
          active: false,
          pathRedirect: mappingRoutes.referential.brands,
        },
        {
          label: 'Ville',
          active: false,
          pathRedirect: mappingRoutes.referential.city,
        },
        {
          label: 'Pays',
          active: false,
          pathRedirect: mappingRoutes.referential.countries,
        },
      ],
    },

    // {
    //   nzSourceImage: 'question-circle.svg',
    //   nzLabel: 'FAQ',
    //   nzActive: false,
    //   nzOpen: false,
    //   nzSubmenu: true,
    //   nzSubmenuItem: [
    //     {
    //       label: 'Catégories',
    //       active: true,
    //       pathRedirect: mappingRoutes.faq.categories.list,
    //     },

    //     {
    //       label: 'Questions',
    //       active: false,
    //       pathRedirect: mappingRoutes.faq.questions.list,
    //     },
    //   ],
    // },

    {
      nzSourceImage: '09.svg',
      nzLabel: 'Services & tarifications',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: true,
      nzSubmenuItem: [
        {
          label: 'Services livraison',
          active: false,
          pathRedirect: mappingRoutes.referential.deliveryService.list,
        },
        {
          label: 'Tarification zones',
          active: false,
          pathRedirect: mappingRoutes.tarifications.list,
        },
      ],
    },
  ];
  isPageChange: boolean = false;
  constructor(private _router: Router, private _utilService: UtilsService) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isPageChange = true;
      } else if (event instanceof NavigationEnd) {
        this.isPageChange = false;
      }
    });
  }

  ngOnInit(): void {
    this.setActiveMenuFromUrl(this._router.url);

    // Écouter les changements de route pour mettre à jour l'état actif
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveMenuFromUrl(event.url);
      }
    });

    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        this.isMenuOpen = false;
      }
    });
  }

  handleMenuClick(menu: IMenuItem) {
    // Si le menu a un lien direct et pas de sous-menu
    if (!menu.nzSubmenu && menu.pathRedirect) {
      this._router.navigate([menu.pathRedirect]);
      this.resetActiveStates();
      menu.nzActive = true;
      return;
    }

    // Si le menu a un sous-menu, toggle l'état ouvert/fermé
    if (menu.nzSubmenu) {
      menu.nzOpen = !menu.nzOpen;
    }
  }
  handleSubmenuClick(event: Event, parentMenu: IMenuItem, submenu: any) {
    // Empêche la propagation du clic vers le menu parent
    event.stopPropagation();

    // Navigation vers la route du sous-menu
    if (submenu.pathRedirect) {
      this._router.navigate([submenu.pathRedirect]);

      // Reset tous les états actifs
      this.resetActiveStates();

      // Active le menu parent et le sous-menu
      parentMenu.nzActive = true;
      submenu.active = true;
    }
  }

  private resetActiveStates() {
    // Reset l'état actif de tous les menus et sous-menus
    this.menus.forEach((menu) => {
      menu.nzActive = false;
      if (menu.nzSubmenuItem) {
        menu.nzSubmenuItem.forEach((submenu) => {
          submenu.active = false;
        });
      }
    });
  }
  // Méthode pour définir l'état actif initial basé sur l'URL courante
  setActiveMenuFromUrl(url: string) {
    this.menus.forEach((menu) => {
      // Vérifie si l'URL correspond au chemin du menu principal
      if (menu.pathRedirect === url) {
        this.resetActiveStates();
        menu.nzActive = true;
        return;
      }

      // Vérifie les sous-menus
      if (menu.nzSubmenuItem) {
        menu.nzSubmenuItem.forEach((submenu) => {
          if (submenu.pathRedirect === url) {
            this.resetActiveStates();
            menu.nzActive = true;
            menu.nzOpen = true;
            submenu.active = true;
          }
        });
      }
    });
  }
  logout() {
    this._utilService.LogOut();
  }

  goParameter() {
    this._router.navigate([mappingRoutes.settings]);
  }

  goMyProfil() {
    this._router.navigate([mappingRoutes.myProfile]);
  }

  goNotifications() {
    this._router.navigate([mappingRoutes.notifications]);
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
