<div
  class="flex items-center justify-between border-b border-gray-100 !mb-4 p-4"
>
  <nz-breadcrumb nzAutoGenerate [nzSeparator]="carre"></nz-breadcrumb>

  <ng-template #carre>
    <span nz-icon nzType="caret-right" nzTheme="outline"></span>
  </ng-template>

  <div class="flex items-center gap-4">
    <div
      class="aspect-square flex items-center justify-center w-12 h-12 bg-gray-100 rounded-full"
    >
      {{ user.lastName.charAt(0) }}
    </div>
    <div
      class="flex items-center gap-2 hover:cursor-pointer"
      nz-dropdown
      [nzDropdownMenu]="menu"
    >
      <div>Administrateur</div>
      <div>
        <span nz-icon nzType="caret-down" nzTheme="outline"></span>
      </div>
    </div>
  </div>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu nzSelectable>
    <li nz-menu-item (click)="logout()">Me déconnecter</li>
  </ul>
</nz-dropdown-menu>
